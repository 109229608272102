import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import globalController from '../../mobx/GlobalController';
import { checkToken } from '../../utils/httpServices/user';
import { PAGES_WITHOUT_TOKEN } from '../../utils/consts';

export default function Layout({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (PAGES_WITHOUT_TOKEN.includes(pathname.toLowerCase())) {
      console.log('No token needed!');
      return;
    }

    if (pathname === '/' || pathname === '/profile') return;
    checkToken()
      .then((res) => {
        if (res.valid) {
          if (res.user) {
            globalController.setUser(res.user);
            localStorage.setItem('user', JSON.stringify(res.user));
          }
        } else {
          if (PAGES_WITHOUT_TOKEN.includes(pathname.toLowerCase())) {
            console.log('No token needed');
            return;
          }
          globalController.resetUser();
          navigate('/login', { replace: true });
        }
      })
      .catch((error) => {
        if (error) {
          globalController.resetUser();
          navigate('/login', { replace: true });
        }
      });

    // const token = localStorage.getItem('token');
    // if ((pathname === '/' || !pathname) && token) {
    //   navigate('/offers');
    // }
  }, [pathname]);

  return <>{children}</>;
}
