export const APP_VERSION = '1.01';

export const URL_API_DEV = 'https://ndadmin.itcom8.pro';
export const URL_API_PROD = 'https://admin.novoedelo.org';
export const URL_BASE = window.location.hostname.includes('новоедело') ? URL_API_PROD : URL_API_DEV;
export const URL_API = URL_BASE + '/api/v1';

export const optionsItemsPerPage = [10, 25, 50, 100, 200];

export const slideInterval = 3000;
export const cardsPerScreen = 3;
export const cardsPerScreenSmall = 1;

export const PAGES_WITHOUT_TOKEN = ['/login', '/news'];
