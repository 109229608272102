import React, { useState } from 'react';
import Card from './Card';
import Modal from './Modal';
import Header from '../../components/Header/Header';
import { RocketIcon } from '../../components/Icons/Rocket';
import clsx from 'clsx';
import { Grid } from 'antd';
import { cardsPerScreen, cardsPerScreenSmall } from '../../utils/consts';
import { CrossIcon } from '../../components/Icons/Cross';
import { useQuery } from 'react-query';
import { acceptOffers, getOffers } from '../../utils/httpServices/offers';
import { IOffer } from './types';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController';

const { useBreakpoint } = Grid;

const isOpenLS = localStorage.getItem('isOpen');

function getCorrectEnding(count: number) {
  if (count % 10 === 1 && count % 100 !== 11) {
    return `${count} предложение`;
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 12 || count % 100 > 14)) {
    return `${count} предложения`;
  } else {
    return `${count} предложений`;
  }
}

const Offers = observer(() => {
  const [isOpen, setIsOpen] = useState(isOpenLS === null ? true : isOpenLS === 'true');
  const [isShowModal, setIsShowModal] = useState(false);

  const { data: offers = [] } = useQuery('offers', () => getOffers(), {
    select: (data) => data.list,
  });

  const selectedIds = globalController.selectedOffersIds;

  const screens = useBreakpoint();
  const isLargeScreen = screens.lg;
  const isSmallScreen = !isLargeScreen;
  const showCardsNum = isLargeScreen ? cardsPerScreen : cardsPerScreenSmall;

  const handleAccept = () => {
    const user_token = localStorage.getItem('token');
    if (!user_token) {
      toast('Вы не авторизованы', { type: 'error' });
      return;
    }

    let data = new FormData();
    selectedIds.forEach((offerId) => {
      data.append('offers[]', `${offerId}`);
    });
    data.append('user_token', user_token);

    acceptOffers(data)
      .then((result: any) => {
        if (result.success) {
          setIsShowModal(true);
        } else {
          toast(result.message || 'Ошибка', { type: 'error' });
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  // useEffect(() => {
  //   const elms = [...document.querySelectorAll('.acard')];
  //   setCards(elms);
  // }, []);

  // useEffect(() => {
  //   if (cards?.length && !isOpen) {
  //     const interval = setInterval(() => {
  //       setSelectedIdx((prevIdx) => (prevIdx + showCardsNum) % cards.length);
  //     }, slideInterval);

  //     return () => clearInterval(interval);
  //   }
  // }, [cards, isOpen]);

  // useEffect(() => {
  //   cards?.[selectedIdx]?.scrollIntoView({
  //     behavior: 'smooth',
  //     inline: 'center',
  //     block: 'nearest',
  //   });
  // }, [selectedIdx]);

  return (
    <div
      className="flex flex-col min-h-screen w-full lg:bg-cover lg:bg-center bg-contain bg-top bg-no-repeat"
      style={{
        backgroundImage: `url(/images/bg${isSmallScreen ? '-small' : ''}.webp)`,
        backgroundColor: '#F6F7F8',
      }}
    >
      {/* <Menu /> */}
      <Header />
      {isOpen && (
        <div
          className="lg:w-2/3 w-[90%] rounded-[20px] bg-white lg:py-[2vw] lg:px-[2.5vw] py-[4vw] px-[5vw] relative mt-[8vh] mb-[11vh] mx-auto"
          style={{ borderTop: '6px solid #F02024' }}
        >
          <RocketIcon className="lg:flex hidden absolute bottom-8 right-9" />
          <CrossIcon
            className="absolute top-[20px] right-[20px] cursor-pointer opacity-50 z-50"
            onClick={() => {
              setIsOpen(false);
              localStorage.setItem('isOpen', JSON.stringify(false));
            }}
          />
          <div className="relative w-fit">
            <div className="lg:hidden flex" style={{ transform: 'scale(0.8) translateX(-24px)' }}>
              <RocketIcon />
            </div>
            <h3 className="font-medium lg:text-[23px] text-[18px] mb-4 lg:w-full w-3/4 lg:mt-0 mt-5">
              Поздравляем вас с открытием своего дела!
            </h3>
            {/* <InfoIcon className="absolute -top-1 -left-14" /> */}
          </div>
          <p className="lg:text-[18px] text-sm mb-4 lg:w-3/4 w-full">
            Вам предстоит многое освоить и многому научиться на вашем пути к успеху в Вашем бизнесе.
          </p>
          <p className="lg:text-[18px] text-sm lg:w-3/4 w-full">
            Для того. чтобы ваш старт был проще и приятнее, на основании данных о Вашем бизнесе наш
            искусственный интеллект подготовил специальные предложения от наших партнеров.
          </p>
        </div>
      )}
      <h2
        className={clsx(
          'font-medium lg:text-[45px] text-[22px] lg:max-w-[700px] mb-12 lg:mx-[12.5%] mx-5 lg:leading-[57px] leading-8 lg:text-left text-center ',
          !isOpen && 'mt-[5vmin]',
          // !isOpen && 'lg:mt-[6vh] mt-auto',
        )}
      >
        Выбрать предложение,
        <br />
        которое подходит именно вам
      </h2>
      <div
        className={clsx(
          'lg:w-[80%] w-full lg:gap-[3%] gap-[1%] mx-auto lg:mb-[230px] mb-[80px] grid select-none',
          `grid-cols-${showCardsNum}`,
        )}
      >
        {offers.map((el: IOffer, idx: number) => (
          <Card key={`${el.id}-${idx}`} data={el} showCardsNum={showCardsNum} />
        ))}
      </div>

      <div
        className={clsx(
          'lg:w-[80%] w-full p-4 rounded-[20px] fixed transition-all left-1/2 -translate-x-1/2 bg-[white]',
          selectedIds.length ? 'bottom-8 opacity-100' : '-bottom-[99vh] opacity-0',
        )}
        style={{
          boxShadow:
            'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
        }}
      >
        <div className="bg-[#F6F7F8] p-4 rounded-[20px] w-full flex justify-between items-center">
          <div>
            <b className="text-[19px]">Выбрано предложений</b>
            <p className="text-base">
              <b>У вас выбрано: </b>
              {getCorrectEnding(selectedIds.length || 0)}
            </p>
          </div>
          <button
            className="bg-[#0A42D3] rounded-[7px] text-base text-white font-medium w-full p-4 mt-auto transition-all md:w-1/4 w-1/3 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => handleAccept()}
            disabled={!selectedIds.length}
          >
            Подключить
          </button>
        </div>
      </div>

      {/* <div className="lg:hidden flex mx-auto lg:my-0 my-9">
        <Select4
          index={selectedIdx / showCardsNum}
          setSelectedIdx={setSelectedIdx}
          quantity={cards.length / showCardsNum}
          showCardsNum={showCardsNum}
        />
      </div> */}
      {isShowModal && <Modal onClose={() => setIsShowModal(false)} />}
    </div>
  );
});

export default Offers;
