import React from 'react';
import { Tag } from '../../components/Tag';
import { useWindowSize } from '@uidotdev/usehooks';
import Radio from '../../components/Radio';
import { URL_BASE } from '../../utils/consts';
import globalController from '../../mobx/GlobalController';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

const Card = observer(({ data, showCardsNum }: { data: any; showCardsNum: number }) => {
  const size = useWindowSize();
  const selectedIds = globalController.selectedOffersIds;
  const checked = selectedIds.includes(data.id);

  return (
    <div
      className={clsx(
        'acard lg:min-w-auto min-w-[90vw] bg-[#fff] rounded-[20px] px-6 pt-4 pb-8 flex flex-col flex-auto shadow-lg',
        'border-2 relative transition-all',
        checked ? 'border-[#0a42d3]' : 'border-[transparent]',
      )}
      style={{
        minWidth: `${
          ((100 / showCardsNum) * (size?.width || window.innerWidth) * 0.75) / 100 -
          (showCardsNum > 2 ? 20 : 0)
        }px`,
      }}
      role="button"
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        globalController.setSelectedOffersIds(
          checked ? selectedIds.filter((id) => id !== data.id) : [...selectedIds, data.id],
        );
      }}
    >
      <Radio checked={checked} className="absolute top-6 right-6" />
      <div className="flex gap-4 items-center mb-6">
        <Tag text={data.tag || '?'} />
        {data?.image?.url && (
          <img src={URL_BASE + data.image?.url} alt="" className="max-h-[40px]" />
        )}
      </div>
      <div className="bg-[#F6F7F8] p-4 rounded-[20px] mb-4 last:mb-0 lg:text-[20px] text-[18px]">
        <h3 className="font-medium mb-[5px]">{data.label}</h3>
        <p className="lg:text-base text-sm">{data.description}</p>
      </div>
    </div>
  );
});

export default Card;
