import React from 'react';

interface Props {
  onClose: () => void;
}

const Modal = ({ onClose }: Props) => (
  <div
    className="fixed top-0 left-0 bottom-0 right-0 bg-[#0008] flex items-center justify-center"
    onClick={onClose}
  >
    <div
      className="rounded-[15px] bg-white min-w-[618px] min-h-[200px] flex justify-center items-center relative"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button className="absolute top-[15px] right-[15px] text-2xl no-hover" onClick={onClose}>
        ✖
      </button>
      <div className="text-center">
        <div className="font-semibold text-2xl">Ваша заявка успешно отправлена!</div>
        <div className="text-xl mt-4">
          В ближайшее время с вами свяжется
          <br />
          наш менеджер для уточнения деталей.
        </div>
      </div>
    </div>
  </div>
);

export default Modal;
