import clsx from 'clsx';
import React from 'react';

export default function Radio({
  label,
  checked,
  onChange,
  className,
  ...otherProps
}: {
  className?: string;
  label?: React.ReactNode;
  checked: boolean;
  onChange?: (val: boolean) => void;
}) {
  return (
    <div className={clsx('flex items-center gap-3 select-none', className)} {...otherProps}>
      <div
        tabIndex={0}
        role="button"
        className={clsx(
          'relative w-[24px] aspect-square rounded-full border-2 cursor-pointer',
          !checked ? 'border-[#C5C5C5]' : 'border-[#0a42d3]',
        )}
        onClick={() => onChange && onChange(!checked)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onChange && onChange(!checked);
          }
        }}
      >
        {checked && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[#0a42d3]">
            ✔
          </div>
        )}
      </div>
      {label && label}
    </div>
  );
}
