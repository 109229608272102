import React from 'react';
import { ArrowRightBtnIcon } from '../Icons/ArrowRightBtn';
import { FreeMode, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import clsx from 'clsx';
import { INews } from './mockData';
import Header from '../Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '../Spinner';
import { URL_BASE } from '../../utils/consts';
import { FormatDate } from '../../utils/helpers';

interface Props {
  data: INews[];
  text?: boolean;
  noHeader?: boolean;
  isLoading?: boolean;
}

export default function News({ data, text, noHeader = false, isLoading = false }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isNotNews = !pathname.toLowerCase().includes('/news');

  return (
    <div>
      {!noHeader && <Header />}
      <div className={clsx('flex flex-col lg:w-3/4 w-full pb-4 mx-auto', 'lg:mt-auto')}>
        <div className="w-full flex justify-between items-center lg:mb-[38px] lg:px-0 px-5 mb-[19px] font-medium">
          <p className="lg:text-[45px] text-[20px] leading-none">Новости</p>
          <div
            className={clsx(
              'flex items-center gap-3 lg:text-[18px] text-xs',
              !isNotNews && 'hidden',
            )}
            role={isNotNews ? 'button' : undefined}
            tabIndex={isNotNews ? 0 : -1}
            onClick={() => isNotNews && navigate('/news')}
          >
            <p>Все новости</p>
            <ArrowRightBtnIcon />
          </div>
        </div>

        {isLoading ? (
          <div className="w-full min-h-[20vh] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="lg:grid grid-cols-3 hidden w-full gap-x-5 gap-y-6">
              {data.map((el, i) => (
                <div key={JSON.stringify(el) + i} className="lg:w-full w-[190px]">
                  <div
                    className="w-full aspect-[1.6] bg-cover h-fit bg-no-repeat rounded-[20px] overflow-hidden mb-4"
                    style={{
                      backgroundImage: `url(${URL_BASE}${
                        el?.image?.url || '/images/placeholder.png'
                      })`,
                    }}
                  />
                  <h3 className="font-medium text-[20px]">{el.title}</h3>
                  <p className="text-base line-clamp-3 mt-3">{el.text}</p>
                </div>
              ))}
            </div>

            <div className="lg:hidden flex max-w-[100vw]">
              <Swiper
                slidesPerView={'auto'}
                className={'cursor-grab active:cursor-grabbing select-none'}
                modules={[FreeMode, Mousewheel]}
                mousewheel={{ forceToAxis: true }}
                freeMode={{ enabled: true, sticky: false }}
                style={{ border: '1px solid transparent' }}
              >
                {data.map((el, i) => (
                  <SwiperSlide
                    className={clsx(
                      text ? '!w-[290px]' : '!w-[190px]',
                      `first:ml-5 mr-2`,
                      text && 'bg-white py-3 px-4 rounded-[20px]',
                    )}
                    key={JSON.stringify(el) + i}
                  >
                    {text ? (
                      <div className="flex text-[9px] font-medium mb-2">
                        Новости • {FormatDate(el.updated_at)}
                      </div>
                    ) : (
                      <div
                        className="w-full aspect-[1.66] bg-cover h-fit bg-no-repeat rounded-[20px] mb-4 overflow-hidden"
                        style={{
                          backgroundImage: `url(${
                            el?.image?.url ? URL_BASE + el.image.url : '/images/placeholder.jpg'
                          })`,
                        }}
                      />
                    )}
                    <h3 className="font-medium text-xs">{el.title}</h3>
                    {text && <p className="text-[10px] line-clamp-3 mt-[10px]">{el.text}</p>}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
