import axiosInstance from '.';

export const getOffers = async (limit = 9999) => {
  try {
    const response = await axiosInstance.get(
      `/offers?limit=${limit}&orderBy=sort&orderDirection=ASC`,
    );
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const acceptOffers = async (data) => {
  try {
    const response = await axiosInstance.post(`/offer/get`, data);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};
